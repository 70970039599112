import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d5a9732"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-cont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["SearchPart"], { ref: "searchRef" }, null, 512 /* NEED_PATCH */),
    _createVNode(_component_router_view),
    ($setup.popShow)
      ? (_openBlock(), _createBlock($setup["PopoverMenu"], {
          key: 0,
          left: $setup.popLeft,
          top: $setup.popTop,
          onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.popShow = false)),
          onCopy: $setup.handleCopy,
          onSearch: $setup.handleSearch
        }, null, 8 /* PROPS */, ["left", "top"]))
      : _createCommentVNode("v-if", true)
  ]))
}