/*
 * @Author: zhaohongyang
 * @Date: 2022-04-19 14:57:38
 * @Description: 工具函数
 */
// el-datepicker > 格式化获取到的毫秒值
export function formatDate(time: number) {
  const date = new Date(time)
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

  return `${year}-${month}-${day}`
}

// el-datepicker > 判断起始时间早于结束时间
export function disabledStartDate(
  time: Record<string, any>,
  dateTime: number
): boolean {
  if (dateTime) {
    return time.getTime() > Date.now() - 8.64e7 || time.getTime() >= dateTime
  }
  return time.getTime() > Date.now() - 8.64e7
}

// el-datepicker > 判断起始时间晚于结束时间
export function disabledEndDate(
  time: Record<string, any>,
  dateTime: number
): boolean {
  if (dateTime) {
    return time.getTime() > Date.now() - 8.64e7 || time.getTime() <= dateTime
  }
  return time.getTime() > Date.now() - 8.64e7
}

// export class isTypeof {
//   constructor(value: any) {

//   },
//   isBoolean: function (sele) {
//     return typeof sele === 'boolean'
//   },
//   isObject: function (sele) {
//     return typeof sele === 'object'
//   },
//   isWindow: function (sele) {
//     return sele === window
//   },
//   isArray: function (sele) {
//     if (aQuery.isObject(sele) && 'length' in sele && !aQuery.isWindow(sele)) {
//       return true
//     }
//     return false
//   },
// }
