
import { ref, watch, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { IObj, IComObj } from './types'
import RetrievalResult from './cpns/retrievalResult.vue'
import searchCaseBox from './cpns/searchCaseBox.vue'
import { deleteAccurate } from '@/utils/common'

export default defineComponent({
  name: 'SearchPart',
  components: {
    RetrievalResult,
    searchCaseBox
  },
  props: {
    filterData: Object
  },
  setup(props: any) {
    const router = useRouter()
    const store = useStore()
    // 行政案例参数
    let formCaseSearchObj: IComObj = ref({
      categoryId: '2',
      rangeId: '',
      searchCase: [],
      fieldId: '',
      fieldLevel: '',
      decideYear: '',
      behaviorId: ''
    })
    let formSearchObj: IComObj = ref({})
    const eternalObj = ref()

    const resultsList = ref<IObj[]>(store.state.searchList) // 检索条件
    const advancedFlag = ref(false) // 高级检索是否显示
    const curPage = ref(router.currentRoute.value.fullPath)
    formSearchObj.value = { ...formCaseSearchObj.value, ...store.state.form }
    eternalObj.value = JSON.parse(JSON.stringify(formSearchObj.value)) // 原始对象

    onBeforeRouteUpdate((to) => {
      curPage.value = to.path
      if (curPage.value === '/SearchList') {
        resultsList.value = []
        formSearchObj.value = {}
        setStore(formSearchObj.value, resultsList)
      }
    })

    watch(
      () => [store.state.formFlag, store.state.searchList],
      ([newFlag, newList], [oldFlag, oldList]) => {
        if (newFlag === 1 && oldFlag === 0) {
          resultsList.value = []
        } else {
          resultsList.value = store.state.searchList
        }
        console.log('watch all resultsList', resultsList.value)
      }
    )
    watch(
      () => [store.state.formFlag, store.state.form],
      ([newFlag, newForm], [oldFlag]) => {
        if (newFlag === 1 && oldFlag === 0) {
          formSearchObj.value = eternalObj.value
        } else {
          formSearchObj.value = {
            ...formCaseSearchObj.value,
            ...store.state.form
          }
        }
        console.log('watch all form', formSearchObj.value)
      },
      {
        deep: true
      }
    )

    // 删除检索条件
    const deleteFilter = (item: any) => {
      type ObjType = keyof typeof eternalObj.value

      if (item === 'all') {
        resultsList.value = []
        Object.keys(eternalObj.value).forEach((item) => {
          formSearchObj.value[item] = eternalObj.value[item as ObjType]
        })
      } else {
        const key = item.type
        deleteAccurate(resultsList.value, item)
        if (typeof formSearchObj.value[item.type] === 'object') {
          // 删除全文/标题检索内容
          formSearchObj.value[item.type].splice(
            formSearchObj.value[item.type].findIndex(
              (i: IObj) => item.id === i
            ),
            1
          )
        } else {
          const levelMaps: any = {
            fieldId: 'fieldLevel'
          }
          Object.keys(levelMaps).forEach((m) => {
            if (key === m) {
              formSearchObj.value[levelMaps[key]] = ''
            }
          })
          Object.keys(eternalObj.value).forEach((item) => {
            if (key === item) {
              formSearchObj.value[item] = eternalObj.value[item as ObjType]
            }
          })
        }
      }
      setStore(formSearchObj.value, resultsList.value)
    }

    // 提交vuex
    const setStore = (form: any, resultsList: any) => {
      store.commit('setForm', form)
      store.commit('setSearchList', resultsList)
    }
    // 检索并跳转
    const onSubmit = (form: any, resultsList: any) => {
      // if (curPage.value === '/Home') {
      //   router.push('/SearchList')
      // }
      router.push('/SearchCaseList')
      formSearchObj.value = form
      setStore(formSearchObj.value, resultsList)
    }
    const searchBoxRef = ref()
    return {
      curPage,
      formSearchObj,
      formCaseSearchObj,
      advancedFlag,
      deleteFilter,
      onSubmit,
      resultsList,
      searchBoxRef
    }
  }
})
