import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!

  return (_openBlock(), _createBlock(_component_el_cascader, {
    "model-value": _ctx.modelValue,
    "popper-class": _ctx.cascaderClass,
    "show-all-levels": false,
    props: _ctx.propsObj,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChange($event)))
  }, null, 8 /* PROPS */, ["model-value", "popper-class", "props"]))
}