
import { ref, watch, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { IObj, IComObj } from './types'
import RetrievalResult from './cpns/retrievalResult.vue'
import AdvancedWrap from './cpns/advancedWrap.vue'
import SearchBox from './cpns/searchBox.vue'
import { deleteAccurate } from '@/utils/common'
// import PageAdvancedWrap from '@/components/advanced-test/page/page-advanced-wrap/index.vue'

export default defineComponent({
  name: 'SearchPart',
  components: {
    RetrievalResult,
    AdvancedWrap,
    SearchBox
    // PageAdvancedWrap
  },
  props: {
    cateId: String,
    filterData: Object
  },
  setup(props: any) {
    const router = useRouter()
    const store = useStore()
    // all检索参数
    let formSearchObj: IComObj = ref({
      categoryId: '1', // 法规/案例
      rangeId: '',
      searchTitle: [], // 检索标题内容
      searchArtical: [], // 检索全文内容
      searchCase: [],
      fuzzyTitleId: '', // 标题精确/模糊
      fuzzyArticalId: '', // 全文精确/模糊
      isSynonym: true, // 同义词
      spaceArticalId: '', // 同篇/同条/同段/同句
      isResults: false, // 结果中检索
      effectId: '', // 效力级别
      rulesId: '', // 法规
      rulesLevel: '',
      areaId: '', // 行政区划
      areaLevel: '',
      pubOrgName: '', // 发布机关
      institutionId: '', // 发布机关
      institutionLevel: '',
      timelinessId: '', // 时效性
      postValue: '', // 发文字号
      releaseYear: '', // 发布年份
      releaseDateStart: '', // 发布日期
      releaseDateEnd: '', // 发布日期
      implementDateStart: '', // 实施日期
      implementDateEnd: '', // 实施日期
      effectIds: [],//效力级别
      timelinessIds: [],//时效性
      thematics: [],//专题分类
      industries: []//法规分类
    })
    formSearchObj.value = { ...formSearchObj.value, ...store.state.form }
    const eternalObj = ref()

    const resultsList = ref<IObj[]>(store.state.searchList) // 检索条件
    const advancedFlag = ref(false) // 高级检索是否显示
    const curPage = ref(router.currentRoute.value.fullPath)
    formSearchObj.value = { ...formSearchObj.value, ...store.state.form }
    eternalObj.value = JSON.parse(JSON.stringify(formSearchObj.value)) // 原始对象

    onBeforeRouteUpdate((to) => {
      curPage.value = to.path
      if (curPage.value === '/Home') {
        // formSearchObj.value.categoryId = categoryState.id
        deleteFilter('all')
        advancedFlag.value = false
        // getFilterListData()
        // } else {
        // formSearchObj.value.categoryId = ''
      }
    })
    // watch(
    //   () => router.currentRoute.value.path,
    //   (newV) => {
    //     nextTick(() => {
    //       console.log(newV)
    //       resultsList.value = []
    //       formSearchObj.value = eternalObj.value
    //     })
    //   }
    // )
    watch(
      () => [store.state.formFlag, store.state.searchList],
      ([newFlag], [oldFlag]) => {
        if (newFlag === 0 && oldFlag === 1) {
          resultsList.value = []
        } else {
          resultsList.value = store.state.searchList
        }
        console.log('watch all resultsList', resultsList.value)
      }
    )
    watch(
      () => [store.state.formFlag, store.state.form],
      ([newFlag], [oldFlag]) => {
        if (newFlag === 0 && oldFlag === 1) {
          formSearchObj.value = eternalObj.value
        } else {
          formSearchObj.value = {
            ...formSearchObj.value,
            ...store.state.form
          }
        }
        console.log('watch all form', formSearchObj.value)
      },
      {
        deep: true
      }
    )

    // 高级检索是否显示
    const showAdvanced = (flag: boolean) => {
      if (flag) {
        advancedFlag.value = !advancedFlag.value
      } else {
        advancedFlag.value = flag
      }
    }

    // 删除检索条件
    const deleteFilter = (item: any) => {
      if (item === 'all') {
        formSearchObj.value = eternalObj.value
        resultsList.value = []
      } else {
        const key = item.type
        deleteAccurate(resultsList.value, item)
        if (typeof formSearchObj.value[item.type] === 'object') {
          // 删除全文/标题检索内容
          formSearchObj.value[item.type].splice(
            formSearchObj.value[item.type].findIndex(
              (i: IObj) => item.id === i
            ),
            1
          )
        } else if (
          item.type === 'releaseDate' ||
          item.type === 'implementDate'
        ) {
          let dateStart = key + 'Start'
          let dateEnd = key + 'End'
          formSearchObj.value[dateStart] = ''
          formSearchObj.value[dateEnd] = ''
        } else {
          const levelMaps: any = {
            rulesId: 'rulesLevel',
            institutionId: 'institutionLevel',
            areaId: 'areaLevel'
          }
          Object.keys(levelMaps).forEach((m) => {
            if (key === m) {
              formSearchObj.value[levelMaps[key]] = ''
            }
          })
          type ObjType = keyof typeof eternalObj.value
          Object.keys(eternalObj.value).forEach((item) => {
            if (key === item) {
              formSearchObj.value[item] = eternalObj.value[item as ObjType]
            }
          })
        }
      }
      setStore(formSearchObj.value, resultsList.value)
    }

    // 提交vuex
    const setStore = (form: any, resultsList: any) => {
      store.commit('setForm', form)
      store.commit('setSearchList', resultsList)
    }
    // 检索并跳转
    const onSubmit = (form: any, resultsList: any) => {
      if (curPage.value === '/Home') {
        router.push('/SearchList')
      }
      formSearchObj.value = form
      setStore(formSearchObj.value, resultsList)
    }
    // 高级检索提交
    const onFormSubmit = (advSearchObj: any, resultsListStatic: any) => {
      formSearchObj.value.isSynonym = true
      formSearchObj.value.isResults = false
      Object.keys(formSearchObj.value).forEach((key: any) => {
        // console.log(advSearchObj[key])
        if (advSearchObj[key] !== '' && !advSearchObj[key]) {
          if (typeof formSearchObj.value[key] === 'boolean') {
            return
          } else {
            formSearchObj.value[key] = ''
          }
        } else {
          // if (key === 'searchTitle' || key === 'searchArtical') {
          if (typeof formSearchObj.value[key] === 'object') {
            formSearchObj.value[key] = []
            advSearchObj[key] &&
            formSearchObj.value[key].push(advSearchObj[key])
          } else {
            formSearchObj.value[key] = advSearchObj[key]
          }
        }
      })
      resultsList.value = JSON.parse(JSON.stringify(resultsListStatic))
      resultsListStatic = []
      formSearchObj.value.isResults =
        formSearchObj.value.searchTitle.length &&
        formSearchObj.value.searchArtical.length

      setStore(formSearchObj.value, resultsList.value)

      if (curPage.value === '/Home') {
        router.push('/SearchList')
      }
    }
    const searchBoxRef = ref()
    return {
      curPage,
      formSearchObj,
      advancedFlag,
      showAdvanced,
      deleteFilter,
      onSubmit,
      onFormSubmit,
      resultsList,
      searchBoxRef
    }
  },
})
