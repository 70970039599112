
import { reactive, watch, defineComponent } from 'vue'
import { formatDate, disabledStartDate, disabledEndDate } from '../utils'
import { IDateState } from '../types'
export default defineComponent({
  emits: ['change'],
  props: {
    start: {
      type: [String, Number],
      default: ''
    },
    end: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props: any, { emit }) {
    watch(
      () => [props.start, props.end],
      ([startVal, endVal]) => {
        dateStartState.date = startVal
        dateStartState.dateTime = new Date(startVal).getTime()
        dateEndState.date = endVal
        dateEndState.dateTime = new Date(endVal).getTime()
      }
    )

    // 起始日期
    const dateStartState: IDateState = reactive({
      date: props.start,
      dateTime: new Date(props.start).getTime(),
      disabledDateFn: (time: Record<string, any>) => {
        return disabledStartDate(time, dateEndState.dateTime)
      },
      handleDate: (value: any): void => {
        dateStartState.dateTime = value
        dateStartState.date = value ? formatDate(value) : ''
        emit('change', [dateStartState.date, dateEndState.date])
      }
    })

    // 结束日期
    const dateEndState: IDateState = reactive({
      date: props.end,
      dateTime: new Date(props.end).getTime(),
      disabledDateFn: (time: Record<string, any>) => {
        return disabledEndDate(time, dateStartState.dateTime)
      },
      handleDate: (value: any): void => {
        dateEndState.dateTime = value
        dateEndState.date = value ? formatDate(value) : ''
        emit('change', [dateStartState.date, dateEndState.date])
      }
    })

    return {
      dateStartState,
      dateEndState
    }
  }
})
