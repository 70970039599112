
import { PropType, defineComponent } from 'vue'
import { IObj } from '../types'

export default defineComponent({
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    list: {
      type: Array as PropType<IObj[]>,
      default: () => {
        return []
      }
    }
  },
  setup(props: any, { emit }) {
    const handleValueChange = (value: any) => {
      // emit('update:modelValue', value)
      // const label = props.list.filter((i: any) => {
      //   return i.id === value
      // })[0].name
      props.list.some((i: any) => {
        if (i.id === value) {
          emit('change', i)
          return true
        }
      })
    }

    return {
      handleValueChange
    }
  }
})
