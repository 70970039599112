
import { PropType, defineComponent } from 'vue'
import { IObj } from '../types'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    color: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    list: {
      type: Array as PropType<IObj[]>,
      default: () => {
        return []
      }
    }
  },
  setup(props: any, { emit }) {
    const handleValueChange = (value: any) => {
      emit('update:modelValue', value)
    }

    return {
      handleValueChange
    }
  }
})
