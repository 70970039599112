/*
  01 项目中引入了 throttle-debounce 来单独去做防抖和节流, 所以不用使用 lodash 来做防抖和节流
  02 lodash 并没有局部引入, 而是全局引入，导致加载包体积很大, 如果想换成局部引入请使用 lodash/es
  03 即便局部引入了，依旧会引入很多额外的模块，因为 lodash 子模块有各自特性, 所以统一换为 throttle-debounce
  04 项目中大量使用了 throttle-debounce, 而 lodash 出现不多，而且并未显式引入, 有隐式依赖危险
*/
// import _ from 'lodash'

import { computed, ref } from 'vue'
import { debounce } from "throttle-debounce"
import { ElMessage } from 'element-plus/lib/components'

import httpRequest from '@/service'

export function useDicts() {
  const gstart = ref(undefined)
  const gend = ref(undefined)
  const waitLoadData = ref({
    starts: [],
    ends: [],
    codes: []
  })
  const codeOptions = ref({
    YES_OR_NOT: [
      { label: '是', value: '1' },
      { label: '否', value: '0' }
    ],
    YES_OR_NOT_INT: [
      { label: '是', value: 1 },
      { label: '否', value: 0 }
    ],
    Examine_INFO_STATE: [
      { label: '暂存', value: '0' },
      { label: '待审核', value: '2' },
      { label: '已审核', value: '1' },
      { label: '退回', value: '3' }
    ],
    SEX: [
      { label: '男', value: '1' },
      { label: '女', value: '2' }
    ],
    SUBMIT_INFO_STATE:
      [{ label: '未提交', value: '0' },
        { label: '已提交', value: '1' }
      ]
  })

  const treeCache = {}

  const getLabelValuesTree = computed(() => {
    return (codeName, startEnd) => {
      if (!codeOptions.value[codeName]) {
        codeOptions.value[codeName] = []
        initCodeOptions([codeName], startEnd)
      }
      if (treeCache[`c_${codeName}`]) {
        return treeCache[`c_${codeName}`]
      }
      const list = codeOptions.value[codeName].map(item => ({
        id: item.id,
        parentId: item.parentid || codeName,
        label: item.dmcpt || item.label,
        value: item.dmcod || item.value
      }))

      const childMap = {}
      list.forEach(item => {
        childMap[`c_${item.parentId}`] = childMap[`c_${item.parentId}`] || []
        childMap[`c_${item.parentId}`].push(item)
      })
      list.forEach(item => {
        const children = childMap[`c_${item.id}`]
        if (children) {
          item.children = children
          item.children.forEach(c => {
            c.parent = item
          })
        }
      })
      if (childMap[`c_${codeName}`] && childMap[`c_${codeName}`].length > 0) {
        treeCache[`c_${codeName}`] = childMap[`c_${codeName}`]
      }
      return childMap[`c_${codeName}`]
    }
  })

  const getLabelValuesTreeList = computed(() => {
    return (codeName, startEnd) => {
      if (!codeOptions.value[codeName]) {
        codeOptions.value[codeName] = []
        initCodeOptions([codeName], startEnd)
      }
      return codeOptions.value[codeName].map(item => ({
        ...item,
        label: item.dmcpt || item.label,
        value: item.dmcod || item.value,
        id: item.id,
        pId: item.parentid
      }))
    }
  })

  const getLabelValues = computed(() => {
    return (codeName, startEnd) => {
      if (!codeOptions.value[codeName]) {
        codeOptions.value[codeName] = []
        initCodeOptions([codeName], startEnd)
      }
      return codeOptions.value[codeName].map(item => ({
        ...item,
        label: item.dmcpt || item.label,
        value: item.dmcod || item.value
      }))
    }
  })

  const findTreeOptionsNode = (tree, value, leaf = false) => {
    tree = tree || []
    let find
    tree.find(item => {
      if ((item.value === value && !leaf) || (leaf && item.value === value && (!item.children || !item.children.length))) {
        find = item
        return true
      } else {
        find = findTreeOptionsNode(item.children || [], value)
        return !!find
      }
    })
    return find
  }


  const registGlobalStartEnd = (gstart, gend) => {
    gstart.value = gstart
    gend.value = gend
  }

  const getRender = (codeName) => {
    return (text) => getLabelByValue(codeName, `${text || ''}`)
  }

  const getLabelsByValues = (codeName, values, cha = '，', each) => {
    if (!values) {
      return values
    }
    let arr = values.split(',')
    arr = arr.sort()
    return arr.map(item => {
      let lab = getLabelByValue(codeName, item)
      if (each) {
        lab = each(item, lab)
      }
      return lab
    }).join(cha)
  }

  const getLabelArrByValueArr = (codeName, values, each) => {
    if (!values) {
      return []
    }
    let arr = values
    arr = arr.sort()
    return arr.map(item => {
      let lab = getLabelByValue(codeName, item)
      if (each) {
        lab = each(item, lab)
      }
      return lab
    })
  }

  const getLabelByValue = (codeName, value) => {
    if (!codeOptions.value[codeName]) {
      codeOptions.value[codeName] = []
      initCodeOptions([codeName])
    }
    const codeitem = (codeOptions.value[codeName] || []).find(item => (item.dmcod || item.value) == value) || {}
    return codeitem.dmcpt || codeitem.label || value
  }

  const initCodeOptions = (codeNames = [], {
    start,
    end
  } = {}) => {
    codeNames.forEach(item => {
      codeOptions.value[item] = []
    })
    waitLoadData.value.starts.push(start)
    waitLoadData.value.ends.push(end)
    waitLoadData.value.codes.push(...codeNames)
    doLoadCode()

    // start && start()
    // this.$http('post_sym_codeItemDefine_listDictionaryBatch', {name: codeNames.join(',')}).then(res => {
    //   this.codeOptions = {...this.codeOptions, ...res.data}
    //   end && end()
    // })
  }

  const doLoadCode = debounce(50, function() {
    const starts = waitLoadData.value.starts
    const ends = waitLoadData.value.ends
    const codes = waitLoadData.value.codes

    waitLoadData.value.starts = []
    waitLoadData.value.ends = []
    waitLoadData.value.codes = []

    if (!codes || !codes.length) {
      return
    }

    starts.forEach(item => {
      item && item()
    })
    gstart.value && gstart.value()

    const params = {
      name: codes.join(',')
    }
    httpRequest.post({ url: 'listDictionaryBatch', params: params }).then((res) => {
      codeOptions.value = {
        ...codeOptions,
        ...res.data
      }
      ends.forEach(item => {
        item && item()
      })
      gend.value && gend.value()
    }).catch((err) => {
      console.log(err)
      ElMessage.error('字典信息加载失败')
    })
  })

  return {
    getLabelValuesTree,
    getLabelValuesTreeList,
    getLabelValues,
    findTreeOptionsNode,
    registGlobalStartEnd,
    getRender,
    getLabelsByValues,
    getLabelArrByValueArr,
    getLabelByValue,
    initCodeOptions
  }
}
