import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-52e65506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-wrap" }
const _hoisted_2 = { class: "common-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_box = _resolveComponent("search-box")!
  const _component_retrieval_result = _resolveComponent("retrieval-result")!
  const _component_advanced_wrap = _resolveComponent("advanced-wrap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_search_box, {
        ref: "searchBoxRef",
        data: _ctx.filterData,
        resultsList: _ctx.resultsList,
        formObj: _ctx.formSearchObj,
        onSubmit: _ctx.onSubmit,
        onExpand: _ctx.showAdvanced
      }, null, 8 /* PROPS */, ["data", "resultsList", "formObj", "onSubmit", "onExpand"])
    ]),
    _createCommentVNode(" 检索条件 "),
    (_ctx.resultsList.length)
      ? (_openBlock(), _createBlock(_component_retrieval_result, {
          key: 0,
          data: _ctx.resultsList,
          onChange: _ctx.deleteFilter
        }, null, 8 /* PROPS */, ["data", "onChange"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" <PageAdvancedWrap /> "),
    _createCommentVNode(" 高级检索框 "),
    _createVNode(_Transition, {
      name: "mode-slide-search",
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_advanced_wrap, {
          data: _ctx.filterData,
          onReset: _ctx.showAdvanced,
          onSubmit: _ctx.onFormSubmit
        }, null, 8 /* PROPS */, ["data", "onReset", "onSubmit"]), [
          [_vShow, _ctx.advancedFlag]
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}