<!--
 * @Author wuyp1
 * @Date 2024年3月27日
 * @Description 人民法院案例库检索
-->
<template>
  <div class="search-section">
    <div class="search-box">
      <div class="simple-search-box">
        <el-input
          size="large"
          class="simple-search-input"
          v-model="simpleSearchContent"
          :placeholder="`在 ${store.state.caseLibrary.total.toLocaleString()} 篇案例中检索`"
          @keyup.enter.prevent="doSimpleSearch"
        >
          <template #prepend>
            <el-select v-model="simpleSearchType" size="large" class="simple-search-select" :teleported="false">
              <el-option label="标题" value="01" />
              <el-option label="全文" value="02" />
            </el-select>
            <div class="split"></div>
          </template>
          <template #append>
            <div class="search-icon-box" title="检索" @click="doSimpleSearch">
              <el-icon><img src="@/assets/images/search-icon.png" alt=""></el-icon>
            </div>
            <!-- <el-button type="primary" size="large" class="hover-light simple-search-button" @click="doSimpleSearch"> 检索 </el-button> -->
          </template>
        </el-input>
      </div>
      <div class="advanced-search-button-box">
        <el-button type="text" @click="doAdvancedSearch">高级检索</el-button>
      </div>
    </div>
    <div class="match-type-box">
      <el-radio-group v-model="matchType">
        <el-radio label="01">精确</el-radio>
        <el-radio label="02">模糊</el-radio>
        <!-- <el-radio label="03">结果中查询</el-radio> -->
      </el-radio-group>
    </div>
    <div class="result-part">
      <span v-show="conditions.length">检索条件</span>
      <ul class="result-ul" v-show="conditions.length">
        <li v-for="c in conditions" :key="c.id">
          {{ c.label }}：{{ c.paraContent }}
          <div class="close-icon-box">
            <el-icon class="close-icon" @click="doClearCondition(c.id)">
              <close />
            </el-icon>
          </div>
        </li>
      </ul>
      <el-icon
        v-show="conditions.length"
        class="result-delete"
        @click="doClearAllCondition"
      >
        <delete />
        <span>清空</span>
      </el-icon>
    </div>
    <transition name="mode-slide-search">
      <div class="advanced-search-box" v-show="advancedSearchVisible">
        <el-icon class="advanced-search-box-arrow">
          <CaretTop />
        </el-icon>
        <el-form ref="advancedSearchForm" :model="advancedSearchFormData" label-width="80">
          <el-row :gutter="40">
            <!-- 标题 -->
            <el-col :span="12">
              <el-form-item prop="title" label="标题">
                <el-input v-model="advancedSearchFormData.title" />
              </el-form-item>
            </el-col>
            <!-- 全文 -->
            <el-col :span="12">
              <el-form-item prop="fulltext" label="全文">
                <el-input v-model="advancedSearchFormData.fulltext" />
              </el-form-item>
            </el-col>
            <!-- 案号 -->
            <el-col :span="12">
              <el-form-item prop="ajzh" label="案号">
                <el-input v-model="advancedSearchFormData.ajzh" />
              </el-form-item>
            </el-col>
            <!-- 案件类型 -->
            <el-col :span="12">
              <el-form-item prop="caseSortId" label="案件类型">
                <el-tree-select
                  :multiple="true"
                  :check-strictly="true"
                  :data="getLabelValuesTree('CPAL_CASESORT_ID')"
                  v-model="advancedSearchFormData.caseSortId"
                >
                </el-tree-select>
              </el-form-item>
              <!-- <el-form-item prop="caseSortId" label="案件类型">
                <el-select multiple v-model="advancedSearchFormData.caseSortId">
                  <el-option v-for="item in getLabelValues('CPAL_CASESORT_ID')" :key="item.value" :value="item.value" :label="item.label">
                  </el-option>
                </el-select>
              </el-form-item> -->
            </el-col>
            <el-col :span="12">
              <el-form-item prop="sortId" label="案由">
                <el-tree-select
                  :multiple="true"
                  :check-strictly="true"
                  :data="getLabelValuesTree('CPAL_SORT_NEW1_ID')"
                  v-model="advancedSearchFormData.sortId"
                >
                </el-tree-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="wslxId" label="文书类型">
                <el-select multiple v-model="advancedSearchFormData.wslxId">
                  <el-option v-for="item in getLabelValues('CPAL_WSXZ_ID')" :key="item.value" :value="item.value" :label="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="slfyId" label="审理法院">
                <el-tree-select
                  :multiple="true"
                  :check-strictly="true"
                  :data="getLabelValuesTree('CPAL_FCOURT_ID')"
                  v-model="advancedSearchFormData.slfyId"
                >
                </el-tree-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="fyjbId" label="法院级别">
                <el-select multiple v-model="advancedSearchFormData.fyjbId">
                  <el-option v-for="item in getLabelValues('CPWS_FYJB_ID')" :key="item.value" :value="item.value" :label="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="slcxId" label="审理程序">
                <el-tree-select
                  :multiple="true"
                  :check-strictly="true"
                  :data="getLabelValuesTree('CPAL_SLCX_ID')"
                  v-model="advancedSearchFormData.slcxId"
                >
                </el-tree-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="13">
                  <el-form-item prop="cprqStart" label="审判时间">
                    <el-date-picker v-model="advancedSearchFormData.cprqStart" type="date" placeholder="开始时间" />
                  </el-form-item>
                </el-col>

                <el-col :span="11">
                  <el-form-item prop="cprqEnd" label-width="0">
                    <el-date-picker v-model="advancedSearchFormData.cprqEnd" type="date" placeholder="结束时间" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="keyword" label="关键词">
                <el-input v-model="advancedSearchFormData.keyword" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="advanced-search-operate-button-box">
          <el-button round type="primary" @click="onSubmit">检索</el-button>
          <el-button round @click="doResetForm(advancedSearchForm)">重置</el-button>
          <el-button round @click="advancedSearchVisible = false">取消</el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, reactive, ref, onMounted, nextTick } from 'vue'
import { CaretTop, Close, Delete } from '@element-plus/icons-vue'
import { useDicts } from '@/utils/codeOptions'
import { useStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { useRoute } from 'vue-router'
import { isLogin, toLogin } from '@/utils/permission'
onMounted(() => {
  // 监听搜索条件展示框的高度变化
  if ('ResizeObserver' in window) {
    const resizeObserver = new window.ResizeObserver(entries => {
      nextTick(() => {
        let count = 0;
        const timer = setInterval(() => {
          const dom = document.getElementsByClassName('breadcrumb')[0]
          if(dom || ++count == 50){
            clearInterval(timer)
            if(dom){
              for (let entry of entries) {
                if(entry.target.offsetHeight) dom.style.marginBottom = entry.target.offsetHeight + 10 + 'px'
                else dom.style.marginBottom = 0
              }
            }
          }
        }, 200);
      })
    });
    // 选择要观察的元素
    setTimeout(() => {
      const element = document.querySelector('.result-part');
      element && resizeObserver.observe(element);
    }, 200);
    
    // 如果你想停止观察  
    // resizeObserver.unobserve(element);  
    // 或者  
    // resizeObserver.disconnect();  
  }
})
const store = useStore()
const route = useRoute()
if (route.query.keyword) {
  store.commit('addResultApiParam', {
    id: 'keyword' + route.query.keyword,
    param: 'keyword',
    value: route.query.keyword,
    label: route.query.keyword
  })
}

if (route.query.paramName && route.query.code && route.query.name) {
  store.commit('addResultApiParam', {
    id: route.query.paramName + route.query.keyword,
    param: route.query.paramName,
    value: route.query.code,
    label: route.query.name
  })
}

const {
  getLabelValuesTree,
  getLabelValuesTreeList,
  getLabelValues,
  findTreeOptionsNode,
  registGlobalStartEnd,
  getRender,
  getLabelsByValues,
  getLabelArrByValueArr,
  getLabelByValue,
  initCodeOptions
} = useDicts()

initCodeOptions(['CPAL_SORT_NEW1_ID', 'CPAL_CASESORT_ID', 'CPAL_SLCX_ID', 'CPAL_FCOURT_ID', 'CPWS_FYJB_ID', 'CPAL_WSXZ_ID'])

const advancedSearchForm = ref(null)
const simpleSearchType = ref('01')
const simpleSearchContent = ref(undefined)
const caseLibraryTotalCount = ref(3000)
const matchType = ref('01')
const advancedSearchVisible = ref(false)
const advancedSearchFormData = reactive({
  ajzh: undefined,
  title: undefined,
  fulltext: undefined,
  caseSortId: [],
  sortId: [],
  wslxId: [],
  slfyId: [],
  fyjbId: [],
  slcxId: [],
  cprqStart: undefined,
  cprqEnd: undefined,
  keyword: undefined
})
const doResetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

const doSimpleSearch = () => {
  if(!isLogin()) return toLogin(route.fullPath);

  if (matchType.value !== '03') {
    store.commit('caseLibraryClearAllCondition')
  }
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: simpleSearchType.value === '01' ? 'title' : 'fulltext',
    value: simpleSearchContent.value,
    label: simpleSearchContent.value
  })
  simpleSearchContent.value = ''
}

const doAdvancedSearch = () => {
  if(!isLogin()) return toLogin(route.fullPath);
  advancedSearchVisible.value = !advancedSearchVisible.value
}
defineExpose({
  simpleSearchContent,
  doSimpleSearch
})
const onSubmit = () => {
  store.state.caseLibrary.headerApiParams = []
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'title',
    value: advancedSearchFormData.title,
    label: advancedSearchFormData.title
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'fulltext',
    value: advancedSearchFormData.fulltext,
    label: advancedSearchFormData.fulltext
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'ajzh',
    value: advancedSearchFormData.ajzh,
    label: advancedSearchFormData.ajzh
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'caseSortId',
    value: advancedSearchFormData.caseSortId,
    label: getLabelArrByValueArr('CPAL_CASESORT_ID', advancedSearchFormData.caseSortId).join('、')
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'sortId',
    value: advancedSearchFormData.sortId,
    label: getLabelArrByValueArr('CPAL_SORT_NEW1_ID', advancedSearchFormData.sortId).join('、')
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'wslxId',
    value: advancedSearchFormData.wslxId,
    label: getLabelArrByValueArr('CPAL_WSXZ_ID', advancedSearchFormData.wslxId).join('、')
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'slfyId',
    value: advancedSearchFormData.slfyId,
    label: getLabelArrByValueArr('CPAL_FCOURT_ID', advancedSearchFormData.slfyId).join('、')
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'fyjbId',
    value: advancedSearchFormData.fyjbId,
    label: getLabelArrByValueArr('CPWS_FYJB_ID', advancedSearchFormData.fyjbId).join('、')
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'slcxId',
    value: advancedSearchFormData.slcxId,
    label: getLabelArrByValueArr('CPAL_SLCX_ID', advancedSearchFormData.slcxId).join('、')
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'cprqStart',
    value: advancedSearchFormData.cprqStart,
    label: advancedSearchFormData.cprqStart
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'cprqEnd',
    value: advancedSearchFormData.cprqEnd,
    label: advancedSearchFormData.cprqEnd
  })
  store.commit('addHeaderApiParam', {
    id: uuidv4(),
    param: 'keyword',
    value: advancedSearchFormData.keyword,
    label: advancedSearchFormData.keyword
  })
  advancedSearchVisible.value = false
}

const doClearCondition = (condiId) => {
  store.commit('caseLibraryClearCondition', condiId)
}
const doClearAllCondition = () => {
  store.commit('caseLibraryClearAllCondition')
}

const conditions = computed(() => {
  const paraLabelMap = {
    type: '案例类型',
    title: '标题',
    fulltext: '全文',
    ajzh: '案号',
    caseSortId: '案件类型',
    sortId: '案由',
    wslxId: '文书类型',
    slfyId: '审理法院',
    fyjbId: '法院级别',
    slcxId: '审理程序',
    cprqStart: '裁判开始日期',
    cprqEnd: '裁判结束日期',
    cprqYear: '裁判年份',
    keyword: '关键词'
  }
  return [...store.state.caseLibrary.headerApiParams, ...store.state.caseLibrary.resultApiParam].map((item) => {
    return {
      id: item.id,
      label: paraLabelMap[item.param],
      paraContent: item.label
    }
  })
})
</script>

<style lang="less" scoped>
// 顶部检索栏
.search-section {
  position: relative;
  height: 244px;
  background: url('~@/assets/images/common-filter-bg.png') no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 106px;
}

// 检索框盒子
.search-box {
  width: 830px;
  display: flex;
  align-items: center;
}

// 主检索框样式
.simple-search-box {
  --el-component-size-large: 52px;

  &:deep(.el-input-group__prepend) {
    background-color: var(--el-fill-color-blank);
    padding: 0;
    .el-input__inner{
      text-align: center;
      letter-spacing: 5px;
      font-size: 16px;
    }
    .el-input__wrapper{
        box-shadow: none !important;
        width: 100%;
        .el-input__inner{
          line-height: 30px;
        }
    }
    .el-select{
      margin: 0 0;
    }
    .el-select__icon{
      margin-left: 0
    }
    .el-select-dropdown__item{
      text-align: center;
      padding: 0 20px;
      letter-spacing: 5px
    }
  }
  :deep(.el-input__wrapper){
    // box-shadow: 0.5px 0 0 0.5px var(--el-input-border-color,var(--el-border-color)) inset;
    box-shadow: none;
  }
  // 主检索框输入框
  .simple-search-input {
    width: 730px;
  }

  // 主检索框下拉
  .simple-search-select {
    width: 100px;

    &:deep(.el-input__inner) {
      text-align: center;
      width: 64px
    }
  }
  .split{
    height: 22px;
    border-left: 2px solid #ddd;
  }
  .search-icon-box{
    width: 60px;
    height: 100%;
    margin: 0 -20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3459A3;
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
      transform: scale(1.05, 1.1);
    }
  }
  // 主检索框按钮
  .simple-search-button {
    --el-button-size: 50px;
    width: 140px;
    font-size: 20px;
    line-height: 20px;
    text-indent: 0.5em;
    letter-spacing: 1em;
    border-radius: 0;
    color: white;
    background-color: #3d8bf6;

    &:hover {
      color: white;
      background-color: #3d8bf6;
    }
  }
}

// 高级检索按钮样式
.advanced-search-button-box {
  margin-left: 21px;

  .el-button {
    --el-button-active-color: white;
    color: white;
  }
}

// 匹配类型样式
.match-type-box {
  margin-top: 8px;
  width: 826px;

  &:deep(.el-radio__label) {
    color: white;
  }
}

// 高级检索弹出框
.advanced-search-box {
  z-index: 99;
  position: absolute;
  top: 168px;
  width: 1200px;
  min-height: 50px;
  padding: 40px 40px 30px 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px lightgrey;

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-date-editor) {
    width: 100%;
  }

  .advanced-search-box-arrow {
    position: absolute;
    color: white;
    font-size: 20px;
    top: -13px;
    right: 225px;
  }

  .advanced-search-operate-button-box {
    text-align: center;
    margin-top: 10px;

    .el-button {
      padding: 8px 22px;
    }

    .el-button--primary {
      background-color: #237bf2;
    }
  }
}

// 检索条件
.result-part {
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: #fff;
  width: 1200px;
  position: absolute;
  left: 50%;
  bottom: -38px;
  transform: translate(-50%, 100%);
  z-index: 2;
  padding: 0px 20px;
  border-radius: 10px;
  & > span {
    flex: 0 0 86px;
    width: 86px;
  }
  .result-ul {
    flex: 1;
    line-height: 32px;
    width: 100%;
    margin-top: 10px;
    li {
      display: inline-block;
      position: relative;
      padding: 5px 30px 5px 15px;
      border-radius: 5px;
      background: #F8F8F8;
      color: #000;
      line-height: 26px;
      margin: 0 10px 10px 0;
      max-width: 100%;
      cursor: default;
      &:hover{
        background-color: #70A9F5;
        color: #fff;
        .close-icon-box{
          background-color: #fff;
          .close-icon{
            color: #2e6cc1;
          }
        }
      }
      .close-icon-box{
        width: 14px;
        height: 14px;
        border-radius: 7px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        .close-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 12px;
        }
      }
    }
  }
  .result-delete {
    flex: 0 0 54px;
    font-size: 16px;
    font-style: inherit;
    cursor: pointer;
    span {
      margin-left: 5px;
    }
    &:hover{
      color: #2e6cc1;
    }
  }
}
</style>
