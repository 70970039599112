
import { ref, reactive, watch, defineComponent, nextTick } from 'vue'
import { CaretTop } from '@element-plus/icons-vue'
import httpRequest from '@/service'
import { IObj, IAdvObj, IComObj,LowObj } from '../types'
import FormDateBreak from './formDateBreak.vue'
import FormRadioGroup from './formRadioGroup.vue'
import FormSelect from './formSelect.vue'

export default defineComponent({
  emits: ['reset', 'submit'],
  components: {
    CaretTop,
    FormSelect,
    FormDateBreak,
    FormRadioGroup
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props: any, { emit }) {
    const resultsListStatic = ref<IObj[]>([]) // 高级检索暂存检索条件


    // 检索条件添加：在添加前需要先判断是否已有条件
    const setResultListStatic = (e: any, state: any) => {
      resultsListStatic.value.forEach((item: any, index: number) => {
        if (item.type === state.key) {
          resultsListStatic.value.splice(index, 1)
        }
      })
      if (state.type === 'input') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e,
          id: e
        })
      } else if (state.type === 'select') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e.name,
          id: e.id
        })
      } else if (state.type === 'cascader') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e.label,
          id: e.id
        })
      } else if (state.type === 'date') {
        resultsListStatic.value.push({
          type: state.key,
          name: state.title + '：' + e[0] + '至' + e[1],
          id: e
        })
      }
    }
    // 检索重置，取消
    const resetForm = (e: any, value = '') => {
      Object.keys(advSearchObj).forEach((key: any) => {
         if (key === releaseDateState.key) {
          advSearchObj[releaseDateState.key + 'Start'] = ''
          advSearchObj[releaseDateState.key + 'End'] = ''
        }else {
          advSearchObj[key] = ''
        }
      })
      titleState.value = ''
      articalState.value = ''
      institutionState.value = ''
      caseCause.value=''
      effectState.value = ''
      postState.value = ''
      releaseDateState.start = ''
      releaseDateState.end = ''
      resultsListStatic.value = []
      value && emit('reset')
    }
    // 提交
    const submitForm = () => {
      emit('submit', advSearchObj, resultsListStatic.value)
      // sessionStorage.setItem('institutionParent', institutionState.ptArr)
      resetForm('', 'cancel')
      resultsListStatic.value = []
    }

     // 案由
     const caseCause = reactive({
      title: '案由',
      type: 'input',
      key: 'caseCause',
      value: '',
      handleClick(value: string) {
        advSearchObj.cause = caseCause.value = value
        setResultListStatic(value, caseCause)
      }
    })

    // 当事人
    const effectState = reactive({
      title: '当事人',
      type: 'input',
      key: 'effectId',
      value: '',
      handleClick(value: string) {
        effectState.value = value
        advSearchObj.party = value
        setResultListStatic(value, effectState)
      }
    })

    // 标题
    const titleState = reactive({
      title: '案件名称',
      type: 'input',
      key: 'titleState',
      value: '',
      handleClick(value: string) {
        advSearchObj.title = titleState.value = value
        setResultListStatic(value, titleState)
      }
    })
    // 全文
    const articalState = reactive({
      title: '案号',
      type: 'input',
      key: 'caseNo',
      value: '',
      handleClick(value: string) {
        advSearchObj.searchArtical = articalState.value = value
        setResultListStatic(value, articalState)
      }
    })
    // 审判单位
    const postState = reactive({
      title: '审判单位',
      type: 'input',
      key: 'postValue',
      value: '',
      handleClick(value: string) {
        advSearchObj.court = postState.value = value
        setResultListStatic(value, postState)
      }
    })

    // 案件类型
    const institutionState = reactive({
      title: '案件类型',
      type: 'select',
      key: 'institutionId',
      value: '',
      data: [],
      getData() {
        httpRequest
          .post({ 
            url: 'listDictionaryBatch',
            params:{name:'CASE_TYPE_ENUM'},
            headers:{'Content-Type':'application/x-www-form-urlencoded'}
          })
          .then((res: any) => {
            institutionState.data = res.data['CASE_TYPE_ENUM'].map((el: { dmabr1: any; dmcod: any ;id:any}) => ({id:el.id,name:el.dmabr1,value:el.dmcod}))
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleClick(obj: any) {
        advSearchObj.caseType = institutionState.value = obj.value
        setResultListStatic(obj, institutionState)
      }
    })
    institutionState.getData()
    // 发布日期
    const releaseDateState = reactive({
      title: '发布日期',
      type: 'date',
      key: 'publicationDate',
      start: '',
      end: '',
      handleClick(date: any) {
        advSearchObj.publicationDateStart = releaseDateState.start = date[0]
        advSearchObj.publicationDateEnd = releaseDateState.end = date[1]
        setResultListStatic(date, releaseDateState)
      }
    })

    // 高级检索参数对象
    const advSearchObj: LowObj = reactive({
      cause: '', //案由
      party: '', // 当事人
      title: '', // 标题
      caseNo: '', //案号
      court: '', //审判单位
      publicationDateStart: '', //发布日期
      publicationDateEnd:'',
      caseType:'' // 案件类型
    })

    return {
      advSearchObj,
      titleState,
      articalState,
      institutionState,
      effectState,
      caseCause,
      postState,
      releaseDateState,
      resetForm,
      submitForm
    }
  }
})
