
// @ts-nocheck
import { defineComponent, nextTick, onMounted } from 'vue'
import { Close, Delete } from '@element-plus/icons-vue'

export default defineComponent({
  emits: ['change'],
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    Close,
    Delete
  },
  setup(props, { emit }) {
    const deleteFilter = (value: any) => {
      emit('change', value)
    }
    onMounted(() => {
    // 监听搜索条件展示框的高度变化
    if ('ResizeObserver' in window) {
        const resizeObserver = new window.ResizeObserver(entries => {
          nextTick(() => {
            let count = 0;
            const timer = setInterval(() => {
              const dom = document.getElementsByClassName('breadcrumb')[0]
              if(dom || ++count == 50){
                clearInterval(timer)
                if(dom){
                  for (let entry of entries) {
                    if(entry.target.offsetHeight) dom.style.marginBottom = entry.target.offsetHeight + 10 + 'px'
                    else dom.style.marginBottom = 0
                  }
                }
              }
            }, 200);
          })
        });
        // 选择要观察的元素
        setTimeout(() => {
          const element = document.querySelector('.result-part');
          element && resizeObserver.observe(element);
        }, 200);
        
        // 如果你想停止观察  
        // resizeObserver.unobserve(element);  
        // 或者  
        // resizeObserver.disconnect();  
      }
    })
    return {
      deleteFilter
    }
  }
})
