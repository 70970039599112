
import { defineComponent, ref } from 'vue'
import httpRequest from '@/service'

export default defineComponent({
  name: 'cascader-box',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    cascaderClass: {
      type: String,
      default: '' // home-cascader
    }
  },
  setup(props, { emit }) {
    const curId = ref('')
    const data = ref()
    const propsObj = {
      checkStrictly: true,
      value: 'id',
      lazy: true,
      lazyLoad(node, resolve) {
        let params = node.data && node.data.value ? { publishUnitId: node.data.value } : {}
        httpRequest.post({ url: 'lazyInstitutionSearch', params: params })
          .then((res) => {
            let nodes = res.data.map(item => ({
              ...item,
              value: item.id,
              label: item.label,
              leaf: !item.hasChildren
            }))
            data.value = (data.value || []).concat(nodes)
            resolve(nodes)
          })
          .catch((err) => {
            console.log(err)
          })
        // setTimeout(() => {
        //   const nodes = Array.from({ length: level + 1 }).map((item) => ({
        //     value: ++id,
        //     label: `Option - ${id}`,
        //     leaf: level >= 2,
        //   }))
        //   // Invoke `resolve` callback to return the child nodes data and indicate the loading is finished.
        //   resolve(nodes)
        // }, 1000)
      }
    }


    const obj = ref()
    const getTreeName = (list: any, id: string): any => {
      return list.some((i: any) => {
        if (i.id === id) {
          obj.value = i
          return true
        } else {
          if (i.children && i.children.length > 0) {
            return getTreeName(i.children, id)
          }
        }
      })
    }
    const handleChange = (cascader: string[]) => {
      const length = cascader !== null ? cascader.length : 0
      const parentArr = cascader.slice(0, cascader.length - 1)
      if (length === 1) {
        curId.value = cascader[0]
      } else if (length === 2) {
        curId.value = cascader[1]
      } else if (length === 3) {
        curId.value = cascader[2]
      }
      let flag = getTreeName(data.value, curId.value)
      if (flag) {
        // 记录级联选择
        emit('update:modelValue', curId.value)
        emit('change', obj.value, parentArr)
      }
    }

    return {
      data,
      propsObj,
      handleChange
    }
  }
})
