// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/common-filter-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".search-wrap[data-v-4f6074c6] {\n  width: 100%;\n  min-height: 244px;\n  padding-top: 70px;\n  position: relative;\n  top: 0px;\n  z-index: 10;\n  transition: all 2s;\n}\n.search-wrap[data-v-4f6074c6]:after {\n  content: '';\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: cover;\n  z-index: 1;\n  transition: all 0.2s;\n}\n.search-wrap .common-main[data-v-4f6074c6] {\n  position: relative;\n  width: 830px;\n  z-index: 3;\n}\n", ""]);
// Exports
module.exports = exports;
