import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    "model-value": _ctx.modelValue,
    placeholder: "",
    style: {"width":"100%"},
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleValueChange($event)))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.id,
          label: item.name,
          value: item.id
        }, null, 8 /* PROPS */, ["label", "value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value"]))
}